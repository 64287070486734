import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslations } from "next-intl";
import Button from "../Button";
import PromoInputIcon from "../../../public/icons/cart/promo-input-icon.svg";
import { useEffect } from "react";
type Props = {
  isLoading?: boolean;
  success: boolean;
  error?: string;
  promoCode?: string;
  onValidate: (promoCode: string) => void;
};
const PromoCodeInput = ({
  promoCode,
  onValidate,
  isLoading,
  success,
  error
}: Props) => {
  const t = useTranslations();
  const {
    register,
    handleSubmit,
    formState: {
      errors
    },
    reset
  } = useForm<{
    promoCode: string;
  }>({
    defaultValues: {
      promoCode
    },
    resolver: zodResolver(z.object({
      promoCode: z.string().min(1, "Don't forget to enter a promo code")
    }))
  });
  useEffect(() => {
    if (promoCode) {
      reset({
        promoCode
      });
    }
  }, [promoCode, reset]);
  const onSubmit: SubmitHandler<{
    promoCode: string;
  }> = data => {
    onValidate(data.promoCode);
  };
  return <div data-sentry-component="PromoCodeInput" data-sentry-source-file="PromoCodeInput.tsx">
      <form onSubmit={handleSubmit(onSubmit)} className="flex">
        <div className="flex flex-1 items-center rounded-lg rounded-e-none border border-e-0 border-gray-300 ps-2 shadow-primary focus-within:border-primary focus-within:shadow-sm">
          <PromoInputIcon data-sentry-element="PromoInputIcon" data-sentry-source-file="PromoCodeInput.tsx" />
          <input type="text" {...register("promoCode")} disabled={isLoading} className="w-full border-0 placeholder:text-gray-500 focus:border-0 focus:ring-transparent" placeholder={t("Common.enterPromoCode")} />
        </div>
        <Button isLoading={isLoading} type="submit" className="rounded-s-none" data-sentry-element="Button" data-sentry-source-file="PromoCodeInput.tsx">
          {t("Common.apply")}
        </Button>
      </form>
      <p className="mt-2">
        {errors.promoCode?.message || error ? <p className="text-sm text-[#DD3056]">{errors.promoCode?.message || error}</p> : success ? <p className="text-sm text-green-500">{t("Common.promoApplied")}</p> : null}
      </p>
    </div>;
};
export default PromoCodeInput;