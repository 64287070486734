"use client";

import { useEffect } from "react";
import { useAtomValue } from "jotai";
import PromoCodeInput from "./PromoCodeInput";
import { useValidatePromoCode } from "@/api/mutations/usePromoCode";
import OrderSummaryIcon from "../../../public/icons/order-summary-icon.svg";
import Button from "../Button";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { promoCodeAtom } from "@/atoms/cart";
import { calculateDiscountValue } from "@/utils/applyPromocode";
import { useTranslations } from "next-intl";
import { InView } from "react-intersection-observer";
type BaseOrderSummaryProps = {
  title: string;
  subtotal: number;
  discount?: number;
  itemsCount: number;
  testId?: string;
  withActions?: boolean;
  onCheckoutButtonViewChange?: (inView: boolean) => void;
};
type CartOrderSummaryProps = BaseOrderSummaryProps & {
  mode: "cart";
  onProceedToCheckout: () => void;
};
type CheckoutOrderSummaryProps = BaseOrderSummaryProps & {
  mode: "checkout";
  shippingPrice: number;
  onCheckout: () => void;
};
type OrderHistoryOrderSummaryProps = BaseOrderSummaryProps & {
  mode: "orderHistory";
  shippingPrice: number;
};
type Props = CartOrderSummaryProps | CheckoutOrderSummaryProps | OrderHistoryOrderSummaryProps;
const OrderSummaryCard = (props: Props) => {
  const {
    withActions = true,
    onCheckoutButtonViewChange = () => {}
  } = props;
  const {
    validatedCode,
    error,
    isLoading,
    validate
  } = useValidatePromoCode();
  const promoCode = useAtomValue(promoCodeAtom);
  const t = useTranslations();
  useEffect(() => {
    if (promoCode) {
      validate(promoCode);
    }
  }, [validate, promoCode]);
  return <div className="@container" data-sentry-component="OrderSummaryCard" data-sentry-source-file="index.tsx">
      <div className="p-4 @md:p-6" data-testid={props.testId}>
        <div className="flex items-center gap-2 pb-4 @md:border-b-[1.5px] @md:border-[#F6F6F6]">
          <OrderSummaryIcon data-sentry-element="OrderSummaryIcon" data-sentry-source-file="index.tsx" />
          <span className="text-lg font-semibold">{props.title}</span>
        </div>
        {props.mode === "cart" && <div className="mt-4">
            <PromoCodeInput promoCode={promoCode} onValidate={validate} success={!!validatedCode} error={error && (error as any).message} isLoading={isLoading} />
          </div>}
        <div className="mt-4 grid gap-y-4">
          <p className="flex justify-between">
            <span className="text-[#666666]">
              {t("Common.subtotal")} ({t("Cart.items_count", {
              count: props.itemsCount
            })})
            </span>
            <span className="font-semibold">
              {props.subtotal} {t("Common.egp")}
            </span>
          </p>
          {validatedCode && <p className="flex justify-between text-primary">
              <span>{t("Common.discount")}</span>
              <span className="font-semibold">
                -
                {props.discount || calculateDiscountValue({
              promoCode: validatedCode,
              totalPrice: props.subtotal
            })}{" "}
                {t("Common.egp")}
              </span>
            </p>}
          {props.mode === "cart" && <p className="flex items-center text-sm text-primary">
              <AiOutlineInfoCircle className="me-1 text-primary" />
              <span>{t("Common.shippingHint")}</span>
            </p>}
          {(props.mode === "checkout" || props.mode === "orderHistory") && <p className="flex justify-between">
              <span className="text-[#666666]">{t("Common.shippingPrice")}</span>
              <span className="font-semibold">
                {props.shippingPrice} {t("Common.egp")}
              </span>
            </p>}
          {props.mode !== "cart" && <p className="flex justify-between border-t-[1.5px] border-t-[#F6F6F6] pt-4 text-lg font-semibold">
              <span>{t("Common.totalPrice")}</span>
              <span>
                {validatedCode ? props.subtotal - calculateDiscountValue({
              promoCode: validatedCode,
              totalPrice: props.subtotal
            }) : props.subtotal}{" "}
                {t("Common.egp")}
              </span>
            </p>}
        </div>
        {props.mode !== "orderHistory" && withActions && <InView onChange={onCheckoutButtonViewChange} threshold={1} rootMargin="0px 0px 100px 0px">
            <Button onClick={props.mode === "cart" ? props.onProceedToCheckout : props.onCheckout} className="mt-4 w-full" isLoading={isLoading}>
              {props.mode === "cart" ? t("Common.ProceedToCheckout") : t("Common.placeOrder")}
            </Button>
          </InView>}
      </div>
    </div>;
};
export default OrderSummaryCard;