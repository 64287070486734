"use client";

type Props = {
  title: string;
  children: React.ReactNode;
};
const ProductFilter = ({
  title,
  children
}: Props) => {
  return <div data-sentry-component="ProductFilter" data-sentry-source-file="Filter.tsx">
      <h2 className="mb-3 text-lg font-medium md:text-lg">{title}</h2>
      {children}
    </div>;
};
export default ProductFilter;