import { SuccessResponse } from "@/types/apiResponse";
import { PromoCode } from "@/types/models/PromoCodeModel";
import { request } from "../utils/request";

export const validatePromoCode = async (promoCode: string) => {
  const response = await request<SuccessResponse<PromoCode>>({
    url: `/api/promo-code/validate`,
    method: "POST",
    data: {
      promoCode,
    },
  });

  return response;
};
