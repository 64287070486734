import { ButtonHTMLAttributes, ReactNode, forwardRef } from "react";
type ButtonSize = "sm" | "md" | "lg";
type ButtonVariant = "primary" | "secondary" | "text";
type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactNode;
  variant?: ButtonVariant;
  size?: ButtonSize;
  fullWidth?: boolean;
  rounded?: boolean;
  isLoading?: boolean;
  className?: string;
};
const variantStyles: { [k in ButtonVariant]: string } = {
  primary: "bg-primary text-primary-50 hover:bg-[linear-gradient(60deg,#ff748e,#a10b78,#452680)] focus:bg-[linear-gradient(60deg,#ff748e,#a10b78,#452680)]",
  secondary: "bg-transparent text-primary border border-primary hover:bg-primary hover:text-primary-50",
  text: "bg-transparent text-primary hover:underline hover:underline-offset-4"
};
const sizeStyles: { [k in ButtonSize]: string } = {
  sm: "text-sm px-3 py-2 h-8",
  md: "px-4 min-h-10 py-2 text-base",
  lg: "text-lg px-6 py-3"
};
const disabledStyles = "cursor-not-allowed opacity-40 font-medium shadow-none";
const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  type = "button",
  children,
  variant = "primary",
  fullWidth = false,
  size = "md",
  rounded = false,
  isLoading = false,
  className = "",
  ...buttonProps
}, ref) => {
  return <button ref={ref} type={type} className={`rounded-lg transition-colors ${variantStyles[variant]} ${sizeStyles[size]} ${fullWidth ? "w-full" : ""} ${rounded ? "rounded-full" : ""} ${buttonProps.disabled || isLoading ? disabledStyles : ""} ${className}`} disabled={buttonProps.disabled || isLoading} {...buttonProps}>
        <span className="relative flex items-center justify-center">{children}</span>
      </button>;
});
Button.displayName = "Button";
export default Button;