"use client";

import { Checkbox } from "@/components/checkbox";
import { FilterSize } from "../types";
import { useSearchParams } from "@/hooks";
import { useState } from "react";
type Props = {
  locale: string;
  size: FilterSize;
};
const SizeFilterOption = ({
  locale,
  size
}: Props) => {
  const {
    searchParams,
    updateSearchParams,
    deleteSearchParam
  } = useSearchParams();
  const [selectedSizes, setSelectedSizes] = useState<string[]>(searchParams.getAll("sizes"));
  const handleSelectSize = (value: string) => {
    setSelectedSizes(prev => prev.includes(value) ? prev.filter(prevValue => prevValue !== value) : [...prev, value]);
    const querySizes = searchParams.getAll("sizes");
    const isSelected = querySizes.includes(value);
    if (isSelected) {
      if (querySizes.length === 1) {
        deleteSearchParam("sizes");
      } else {
        updateSearchParams("sizes", querySizes.filter(querySize => querySize !== value));
      }
    } else {
      updateSearchParams("sizes", querySizes.length === 0 ? [value] : [...querySizes, value]);
    }
  };
  return <div className="flex items-center gap-2" data-sentry-component="SizeFilterOption" data-sentry-source-file="SizeFilterOption.tsx">
      <Checkbox id={size.id} checked={selectedSizes.includes(size.value)} onCheckedChange={() => handleSelectSize(size.value)} className="text-white" data-sentry-element="Checkbox" data-sentry-source-file="SizeFilterOption.tsx" />
      <label htmlFor={size.id}>{locale === "en" ? size.nameEn : size.nameAr}</label>
    </div>;
};
export default SizeFilterOption;