import { useMedia } from "react-use";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config";
import { useMemo } from "react";

export const useBreakPoints = () => {
  const twConfig = resolveConfig(tailwindConfig);
  const isSmall = useMedia(`(max-width: ${twConfig.theme.screens["sm"]})`, true);
  const isMedium = useMedia(`(max-width: ${twConfig.theme.screens["md"]})`, true);
  const isLarge = useMedia(`(max-width: ${twConfig.theme.screens["lg"]})`, true);
  const isXl = useMedia(`(max-width: ${twConfig.theme.screens["xl"]})`, true);
  const is2xl = useMedia(`(max-width: ${twConfig.theme.screens["2xl"]})`, true);

  const currentBreakpoint: "sm" | "md" | "lg" | "xl" | "2xl" = useMemo(() => {
    if (isSmall) return "sm";
    if (isMedium) return "md";
    if (isLarge) return "lg";
    if (isXl) return "xl";
    return "2xl";
  }, [isSmall, isMedium, isLarge, isXl]);

  return {
    isSmall,
    isMedium,
    isLarge,
    isXl,
    is2xl,
    currentBreakpoint,
  };
};
