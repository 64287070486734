import type { Config } from "tailwindcss";

const tailwindConfig = {
  content: ["./src/**/*.{js,ts,jsx,tsx}"],
  theme: {
    extend: {
      fontFamily: {
        painter: ["SignPainter", "sans-serif"],
        latin: ["var(--poppins)", "sans-serif"],
        arabic: ["var(--rubik)", "sans-serif"],
      },
      colors: {
        primary: {
          DEFAULT: "#880865",
          50: "#FEEFFA",
          100: "#FCC6ED",
          200: "#F77ED6",
          300: "#F336BF",
          400: "#D00C9B",
          500: "#880865",
          600: "#700753",
          700: "#580541",
          800: "#40042F",
          900: "#28021D",
        },
        "card-bg": "rgba(111, 49, 110, .06)",
      },
      boxShadow: {
        "hot-deals": "0 12px 31px 0 rgba(191, 191, 191, 0.5)",
        "bottom-sheet": "0px -4px 15px 0px rgba(49, 49, 49, 0.12)",
        card: "0px 4px 18px 0px rgba(51, 51, 51, 0.04);",
      },
      lineClamp: {
        14: "14",
      },
      scale: {
        flip: "-1",
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
      },
      backgroundImage: {
        footer: "url('/images/footer-1.png')",
      },
    },
  },
  plugins: [require("@tailwindcss/forms"), require("@tailwindcss/container-queries")],
} satisfies Config;

export default tailwindConfig;
