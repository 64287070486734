"use client";

import { useTranslations } from "next-intl";
import React, { useState } from "react";
import ReactPlayer from "react-player/lazy";
type YoutubeEmbedProps = {
  url: string;
};
const YoutubeEmbed = (props: YoutubeEmbedProps) => {
  const t = useTranslations();
  const [isReady, setIsReady] = useState(false);
  return <div className="relative h-full w-full" data-sentry-component="YoutubeEmbed" data-sentry-source-file="index.tsx">
      {!isReady && <div role="status" className="absolute inset-0 z-10 flex animate-pulse items-center justify-center rounded-lg bg-gray-300 dark:bg-gray-300">
          <svg className="h-12 w-12 text-gray-200 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 384 512">
            <path d="M361 215C375.3 223.8 384 239.3 384 256C384 272.7 375.3 288.2 361 296.1L73.03 472.1C58.21 482 39.66 482.4 24.52 473.9C9.377 465.4 0 449.4 0 432V80C0 62.64 9.377 46.63 24.52 38.13C39.66 29.64 58.21 29.99 73.03 39.04L361 215z" />
          </svg>
          <span className="sr-only">{t("Common.loading")}</span>
        </div>}
      <ReactPlayer url={props.url} width="100%" height="100%" controls={true} onReady={() => setIsReady(true)} data-sentry-element="ReactPlayer" data-sentry-source-file="index.tsx" />
    </div>;
};
export default YoutubeEmbed;