export type HexColor = `#${string}`;

export const calculateColorLuminance = (color: HexColor) => {
  let r = "",
    g = "",
    b = "";

  if (color.length === 4) {
    r = "0x" + color[1] + color[1];
    g = "0x" + color[2] + color[2];
    b = "0x" + color[3] + color[3];
  } else {
    r = "0x" + color[1] + color[2];
    g = "0x" + color[3] + color[4];
    b = "0x" + color[5] + color[6];
  }

  return (parseInt(r, 16) * 0.2126 + parseInt(g, 16) * 0.7152 + parseInt(b, 16) * 0.0722) / 255;
};
