"use client";

import { Fragment } from "react";
import { twMerge } from "tailwind-merge";
import { Dialog, Transition } from "@headlessui/react";
type Props = {
  isOpen: boolean;
  title?: React.ReactNode;
  children: React.ReactNode;
  contentClassName?: string;
  onClose: () => void;
};
const Modal = ({
  isOpen,
  title,
  children,
  onClose,
  contentClassName
}: Props) => {
  return <Transition as={Fragment} show={isOpen} data-sentry-element="Transition" data-sentry-component="Modal" data-sentry-source-file="index.tsx">
      <Dialog className="relative" as="div" onClose={onClose} data-sentry-element="Dialog" data-sentry-source-file="index.tsx">
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
          <div className="fixed inset-0 z-20 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 z-20 grid w-full place-items-center">
          <div className="w-full p-4 text-center md:w-auto">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
              <Dialog.Panel className={twMerge(`relative w-full rounded-2xl bg-white p-4 md:max-w-2xl md:px-10 md:py-6 ${contentClassName}`)} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                {title && <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {title}
                  </Dialog.Title>}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>;
};
export default Modal;