"use client";

import { useParams } from "next/navigation";

export function useLocalizedKey() {
  let locale: "en" | "ar" = "en";
  const params = useParams() as { locale: "en" | "ar" };

  if (typeof window !== "undefined") {
    locale = params.locale;
  }

  const getLocalizedKey = <T extends Record<string, unknown>>(record: T, { en, ar }: { en: keyof T; ar: keyof T }) => {
    if (typeof window === "undefined") {
      return record[en] as string;
    }

    return (locale === "en" ? record[en] : record[ar]) as string;
  };

  return getLocalizedKey;
}
