import { z } from "zod";
import qs from "qs";

const paramsSchema = z.object({
  ids: z.array(z.coerce.number().positive()).optional(),
  page: z.coerce.number().positive().default(1).optional(),
  pageSize: z.coerce.number().positive().default(10).optional(),
  pagination: z
    .object({
      page: z.coerce.number().positive().optional().default(1),
      pageSize: z.coerce.number().positive().optional().default(10),
    })
    .optional(),
  populate: z.array(z.string()).optional(),
  filters: z.record(z.unknown()).optional(),
});

type ExecuteRequestArgs = {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  params?: z.infer<typeof paramsSchema>;
  data?: Record<string, unknown>;
};

export async function request<TResponse>({ url, method, params, data }: ExecuteRequestArgs): Promise<TResponse> {
  const serializedParams = qs.stringify(params, { arrayFormat: "brackets" });

  if (serializedParams) {
    url = `${url}?${serializedParams}`;
  }

  const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    next: {
      revalidate: 60 * 60 * 3,
    },
  });

  const jsonRes = await response.json();

  if (!response.ok) {
    return Promise.reject(jsonRes);
  }

  return jsonRes as TResponse;
}
