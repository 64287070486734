import { PromoCode } from "@/types/models/PromoCodeModel";

type CalculateDiscountValueInput = {
  promoCode: PromoCode;
  totalPrice: number;
};

export const calculateDiscountValue = ({ promoCode, totalPrice }: CalculateDiscountValueInput) => {
  if (promoCode.discountType === "AMOUNT") {
    return promoCode.discountAmount;
  }

  if (promoCode.discountType === "PERCENTAGE") {
    return (totalPrice * promoCode.discountAmount) / 100;
  }

  return 0;
};
