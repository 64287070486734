import { atom, ExtractAtomValue } from "jotai";
import { atomWithStorage } from "jotai/utils";

type ProductCartItem = {
  id: string;
  size: string;
  price: number;
  quantity: number;
};

export const productCartAtom = atomWithStorage<ProductCartItem[]>("productCart", []);

export const isCartDialogOpenAtom = atom(false);
export const toBeRemovedItemAtom = atom<string | null>(null);

// derived Atoms
export const isDeleteItemDialogOpenAtom = atom((get) => get(toBeRemovedItemAtom) !== null);
export const cartSubtotalAtom = atom<number>((get) => {
  const cart = get(productCartAtom);
  return cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
});
export const cartItemsCountAtom = atom<number>((get) => {
  const cart = get(productCartAtom);
  return cart.reduce((acc, item) => acc + item.quantity, 0);
});

// Action Atoms
export const addToCartAtom = atom(null, (get, set, item: Omit<ProductCartItem, "quantity">) => {
  const cart = get(productCartAtom);
  const existingItem = cart.find(({ id, size }) => id === item.id && size === item.size);
  if (existingItem) {
    set(
      productCartAtom,
      cart.map((i) => (i.id === item.id ? { ...i, quantity: i.quantity + 1 } : i))
    );
  } else {
    set(productCartAtom, [...cart, { ...item, quantity: 1 }]);
  }
});

type UpdateCartItemQuantityInput = {
  variantId: string;
  quantity: number;
};

export const updateCartItemQuantityAtom = atom(
  null,
  (get, set, { variantId, quantity }: UpdateCartItemQuantityInput) => {
    const cart = get(productCartAtom);
    set(
      productCartAtom,
      cart.map((i) => (i.id === variantId ? { ...i, quantity } : i))
    );
  }
);

export const removeCartItemAtom = atom(null, (get, set) => {
  const cart = get(productCartAtom);
  set(
    productCartAtom,
    cart.filter((i) => i.id !== get(toBeRemovedItemAtom))
  );
  set(toBeRemovedItemAtom, null);
});

export const emptyCartAtom = atom(null, (_, set) => {
  set(productCartAtom, []);
});

export type ProductCartAtom = ExtractAtomValue<typeof productCartAtom>;
