"use client";

import { useState, useEffect } from "react";
import { useRouter, usePathname, useParams, useSearchParams } from "next/navigation";
import Image from "next/image";
import Link from "next/link";
import { FaShoppingCart } from "react-icons/fa";
import { cartAtom } from "@/atoms/cart";
import { cartItemsCountAtom } from "@/atoms/productCart";
import { useAtom, useAtomValue } from "jotai";
const getFirstNameInitialState = () => {
  if (typeof window === "undefined") {
    return "";
  }
  const user = localStorage.getItem("user");
  if (!user) {
    return "";
  }
  const parsedUser = JSON.parse(user);
  return parsedUser.firstName;
};
type Props = {
  translations: {
    home: string;
    aboutUs: string;
    contactUs: string;
    signIn: string;
    designYourSkirt: string;
    welcome: string;
  };
};
const Navbar = ({
  translations
}: Props) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchQuery = useSearchParams();
  const [cart] = useAtom(cartAtom);
  const [isOpen, setIsOpen] = useState(false);
  const cartItemsCount = useAtomValue(cartItemsCountAtom);
  const [scrollPositionChanged, setScrollPositionChanged] = useState(false);
  const {
    locale = "en"
  } = useParams();
  const [, setFirstName] = useState(getFirstNameInitialState());
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setScrollPositionChanged(true);
    } else {
      setScrollPositionChanged(false);
    }
  };
  const handleChangeLocale = () => {
    const stringifiedSearchQuery = searchQuery.toString();
    const newPathname = pathname.startsWith("/en") ? pathname.replace("/en", "/ar") : pathname.replace("/ar", "/en");
    router.push(`${newPathname}?${stringifiedSearchQuery}`);
  };
  useEffect(() => {
    changeBackground();
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeBackground);
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  });
  const urlsArr = ["/", "/#contact", "/#about"];
  const navClasses = `${scrollPositionChanged ? "bg-white" : "header-bg"} py-1.5 px-4 font-medium text-[#1a201d] 
  md:py-2 md:px-[110px] border-b container mx-auto`;
  useEffect(() => {
    const handleStorage = () => {
      const user = localStorage.getItem("user");
      if (!user) {
        return;
      }
      const parsedUser = JSON.parse(user);
      setFirstName(parsedUser.firstName);
    };
    window.addEventListener("storage", handleStorage);
    return () => {
      window.removeEventListener("storage", handleStorage);
    };
  }, []);
  return <nav className={navClasses} data-sentry-component="Navbar" data-sentry-source-file="index.tsx">
      <div className="grid grid-flow-col justify-between">
        <div className="flex items-center gap-x-4 hover:cursor-pointer md:hidden">
          {/* <div className="text-primary" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <CloseIcon /> : <MenuIcon />}
           </div> */}
          <span onClick={handleChangeLocale} className="ltr:font-arabic">
            {locale === "en" ? "العربية" : "English"}
          </span>
        </div>

        <Link href="/" className="relative h-12 w-12 place-self-center hover:cursor-pointer md:h-14 md:w-14" data-sentry-element="Link" data-sentry-source-file="index.tsx">
          <Image src="/icons/nav-logo.png" fill alt="Gounela logo" className="object-fill" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        </Link>
        <div className="hidden md:mx-8 md:flex md:flex-1 md:items-center md:justify-between md:gap-x-4">
          {/* <ul className="flex items-center gap-x-8">
            <Link href="/">
              <li className="hover:cursor-pointer">{translations.home}</li>
            </Link>
            <Link href="/#about">
              <li className="hover:cursor-pointer">{translations.aboutUs}</li>
            </Link>
            <Link href="/#contact">
              <li className="hover:cursor-pointer">{translations.contactUs}</li>
            </Link>
           </ul> */}

          <ul className="flex items-center gap-x-8">
            <li className="hover:cursor-pointer ltr:font-arabic" onClick={handleChangeLocale}>
              {locale === "en" ? "العربية" : "English"}
            </li>
            {/* <SignedIn>
              <UserButton />
             </SignedIn>
             <SignedOut>
              <SignInButton>
                <Button variant="text">{translations.signIn}</Button>
              </SignInButton>
             </SignedOut> */}
            <li>
              {/* <Button rounded>
                <Link href="/creator" locale={locale as string}>
                  {translations.designYourSkirt}
                </Link>
               </Button> */}
            </li>
            <Link href="/my-cart" data-sentry-element="Link" data-sentry-source-file="index.tsx">
              <div className="relative hover:cursor-pointer">
                {cartItemsCount > 0 && <span className="-ltr:right-1 absolute -bottom-1 z-10 flex aspect-auto h-4 w-4 items-center justify-center rounded-full border border-white bg-primary-500 p-1 text-xs text-white rtl:right-3">
                    {cartItemsCount}
                  </span>}
                <FaShoppingCart size={24} color={"#880865"} className="rtl:scale-x-flip" data-sentry-element="FaShoppingCart" data-sentry-source-file="index.tsx" />
              </div>
            </Link>
          </ul>
        </div>
        <div className="flex items-center gap-x-4 md:hidden md:w-auto">
          {/* <div>
            <SignedIn>
              <UserButton />
            </SignedIn>
            <SignedOut>
              <SignInButton>
                <Button variant="text">{translations.signIn}</Button>
              </SignInButton>
            </SignedOut>
           </div> */}
          <Link href="/my-cart" data-sentry-element="Link" data-sentry-source-file="index.tsx">
            <div className="relative hover:cursor-pointer">
              {cart.length > 0 && <span className="-ltr:right-1 absolute -bottom-1 z-10 flex aspect-auto h-4 w-4 items-center justify-center rounded-full border border-white bg-primary-500 p-1 text-xs text-white rtl:right-3">
                  {cart.length}
                </span>}
              <FaShoppingCart size={24} color={"#880865"} className="rtl:scale-x-flip" data-sentry-element="FaShoppingCart" data-sentry-source-file="index.tsx" />
            </div>
          </Link>
        </div>
      </div>
      {/* {isOpen && (
        <ul className="flex flex-col content-center justify-center divide-y divide-primary py-2 text-center font-medium text-[#1a201d] md:hidden">
          <Link href="/" legacyBehavior>
            <li className="border-t border-primary bg-white py-2 hover:cursor-pointer">{translations.home}</li>
          </Link>
          <Link href={urlsArr.some((el) => el === pathname) ? "#about" : "/#about"} legacyBehavior>
            <li className="bg-white py-2 hover:cursor-pointer">{translations.aboutUs}</li>
          </Link>
          <Link href={urlsArr.some((el) => el === pathname) ? "#contact" : "/#contact"} legacyBehavior>
            <li className="bg-white py-2 hover:cursor-pointer">{translations.contactUs}</li>
          </Link>
          <Link href="/creator?category=2" locale={locale as string} passHref className="grow-0" legacyBehavior>
            <li className="rounded-3xl bg-primary px-6 py-4 text-sm text-[#f8f8f8] hover:cursor-pointer">
              {translations.designYourSkirt}
            </li>
          </Link>
        </ul>
       )} */}
    </nav>;
};
export default Navbar;