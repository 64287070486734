"use client";

import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useSearchParams } from "@/hooks";
import { FaCheck } from "react-icons/fa6";
import { FilterColor } from "../types";
import { calculateColorLuminance } from "@/utils/colors";
type Props = {
  locale: string;
  color: FilterColor;
};
const ColorFilterButton = ({
  locale,
  color
}: Props) => {
  const {
    searchParams,
    updateSearchParams,
    deleteSearchParam
  } = useSearchParams();
  const [isSelected, setIsSelected] = useState<boolean>(searchParams.getAll("colors").includes(color.color));
  const handleSelectColor = useDebouncedCallback(() => {
    const colors = searchParams.getAll("colors");
    if (colors.length === 1 && colors[0] === color.color) {
      deleteSearchParam("colors");
    } else {
      updateSearchParams("colors", colors.includes(color.color) ? colors.filter(id => id !== color.color) : [...colors, color.color]);
    }
  }, 200);
  return <button key={color.id} onClick={() => {
    setIsSelected(prev => !prev);
    handleSelectColor();
  }} className="grid size-6 place-items-center" style={{
    backgroundColor: color.color,
    border: color.color === "#FFFFFF" ? "1px solid black" : "1px solid transparent"
  }} title={locale === "en" ? color.nameEn : color.nameAr} data-sentry-component="ColorFilterButton" data-sentry-source-file="ColorFilterButton.tsx">
      {isSelected && <FaCheck className="text-white" style={{
      color: calculateColorLuminance(color.color) > 0.5 ? "black" : "white"
    }} />}
    </button>;
};
export default ColorFilterButton;