"use client";

import { useState } from "react";
// import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Filter from "./Filter";
import { useSearchParams } from "@/hooks";
import { Input } from "../input";
type Props = {
  minPrice: number;
  maxPrice: number;
  locale: string;
  i18n: {
    min: string;
    max: string;
  };
};
const PriceFilter = ({
  minPrice,
  maxPrice,
  i18n
}: Props) => {
  const {
    searchParams,
    updateSearchParams
  } = useSearchParams();
  const [min, setMin] = useState(Number(searchParams.get("minPrice")) || minPrice / 100);
  const [max, setMax] = useState(Number(searchParams.get("maxPrice")) || maxPrice / 100);
  const handleChangeMin = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "" || e.target.value === "0") {
      setMin(0);
      updateSearchParams("minPrice", "1");
      return;
    }
    if (!e.target.value.match(/^\d*$/)) {
      return;
    }
    if (e.target.valueAsNumber > max) {
      setMin(max);
      updateSearchParams("minPrice", (max * 100).toString());
      return;
    }
    setMin(Number(e.target.value));
    updateSearchParams("minPrice", (Number(e.target.value) * 100).toString());
  };
  const handleChangeMax = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setMax(maxPrice);
      updateSearchParams("maxPrice", maxPrice.toString());
      return;
    }
    if (!e.target.value.match(/^\d*$/)) {
      return;
    }
    setMax(Number(e.target.value));
    updateSearchParams("maxPrice", e.target.value);
  };
  return <Filter title="Price" data-sentry-element="Filter" data-sentry-component="PriceFilter" data-sentry-source-file="PriceFilter.tsx">
      {/* <Slider
               range
        min={minPrice}
        max={maxPrice}
        value={[min, max]}
        onChange={(val) => handleChange(val as number[])}
       /> */}
      <div className="flex gap-2">
        <div>
          <label htmlFor="minPrice">{i18n.min}</label>
          <Input inputMode="numeric" name="minPrice" value={min} onChange={handleChangeMin} max={maxPrice} min={minPrice} pattern="\d*" data-sentry-element="Input" data-sentry-source-file="PriceFilter.tsx" />
        </div>
        <div>
          <label htmlFor="maxPrice">{i18n.max}</label>
          <Input id="maxPrice" inputMode="numeric" name="maxPrice" value={max} onChange={handleChangeMax} max={maxPrice} min={minPrice} pattern="\d*" data-sentry-element="Input" data-sentry-source-file="PriceFilter.tsx" />
        </div>
      </div>
    </Filter>;
};
export default PriceFilter;