import { atomWithReset, atomWithStorage } from "jotai/utils";
import { ExtractAtomValue, atom } from "jotai";

import { creatorAtom } from "./creator";
import { NonNullableFields } from "@/types/utils";

export type CartItem = NonNullableFields<ExtractAtomValue<typeof creatorAtom>> & {
  id: string;
  subtotal: number;
  quantity: number;
};

export const cartAtom = atomWithStorage<CartItem[]>("cart", []);

export const subtotalPriceAtom = atom((get) =>
  get(cartAtom).reduce((cartSubtotal, cartItem) => cartItem.quantity * cartItem.subtotal + cartSubtotal, 0)
);

export const cartItemsCountAtom = atom((get) =>
  get(cartAtom).reduce((cartItemsCount, cartItem) => cartItem.quantity + cartItemsCount, 0)
);

export const shippingPriceAtom = atom(() => 40);

export const editCartItemIdAtom = atomWithReset<null | string>(null);

export const promoCodeAtom = atomWithStorage("promoCode", "");
