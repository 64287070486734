import { useCallback } from "react";
import { useSearchParams as nextUseSearchParams, useRouter, usePathname } from "next/navigation";

export const useSearchParams = () => {
  const searchParams = nextUseSearchParams();
  const pathname = usePathname();
  const router = useRouter();

  const createQueryString = useCallback(
    (name: string, value: string | string[]) => {
      const params = new URLSearchParams(searchParams.toString());
      if (Array.isArray(value)) {
        params.delete(name);
        value.forEach((val) => {
          params.append(name, val);
        });
      } else {
        params.set(name, value);
      }

      return params.toString();
    },
    [searchParams]
  );

  const updateSearchParams = useCallback(
    (name: string, value: string | string[], options: { replace: boolean } = { replace: false }) => {
      const queryString = createQueryString(name, value);
      if (options.replace) {
        router.replace(`${pathname}?${queryString}`);
      } else {
        router.push(`${pathname}?${queryString}`);
      }
    },
    [createQueryString, pathname, router]
  );

  const deleteSearchParam = useCallback(
    (name: string, options: { replace: boolean } = { replace: false }) => {
      const params = new URLSearchParams(searchParams.toString());
      params.delete(name);
      const queryString = params.toString();
      if (options.replace) {
        router.replace(`${pathname}?${queryString}`);
      } else {
        router.push(`${pathname}?${queryString}`);
      }
    },
    [searchParams, pathname, router]
  );

  return {
    searchParams,
    createQueryString,
    updateSearchParams,
    deleteSearchParam,
  };
};
