"use client";

import Button from "../Button";
import { Sheet, SheetClose, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle } from "../sheet";
import { useBreakPoints, useSearchParams } from "@/hooks";
type Props = {
  children: React.ReactNode;
  i18n: {
    filter: string;
    clear_filters: string;
    apply_changes: string;
    filter_description: string;
  };
};
const ProductsFiltersClient = ({
  children,
  i18n
}: Props) => {
  const {
    isSmall
  } = useBreakPoints();
  const {
    searchParams,
    deleteSearchParam
  } = useSearchParams();
  return isSmall ? <Sheet open={searchParams.get("filter") === "true"} onOpenChange={() => deleteSearchParam("filter", {
    replace: true
  })}>
      <SheetContent side="bottom" className="rounded-t-md bg-white">
        <SheetHeader>
          <SheetTitle>{i18n.filter}</SheetTitle>
        </SheetHeader>
        <SheetDescription className="sr-only">{i18n.filter_description}</SheetDescription>
        {children}
        <SheetFooter className="mt-4 w-full flex-row gap-2">
          <Button variant="text" fullWidth>
            {i18n.clear_filters}
          </Button>
          <SheetClose asChild>
            <Button fullWidth>{i18n.apply_changes}</Button>
          </SheetClose>
        </SheetFooter>
      </SheetContent>
    </Sheet> : children;
};
export default ProductsFiltersClient;