import { InputHTMLAttributes, ReactNode } from "react";
import { FieldValues, Path, UseFormRegister } from "react-hook-form";
export type InputProps<T extends FieldValues> = InputHTMLAttributes<HTMLInputElement> & {
  label: ReactNode;
  register: UseFormRegister<T>;
  name: Path<T>;
  errorMessage?: string;
  hint?: ReactNode;
};
function Input<T extends FieldValues>({
  register,
  name,
  label,
  errorMessage,
  hint,
  ...inputProps
}: InputProps<T>) {
  return <div className="flex flex-1 flex-col" data-sentry-component="Input" data-sentry-source-file="index.tsx">
      <label htmlFor={name} className="mb-1 ml-1 inline-block font-medium capitalize">
        {label}
      </label>
      <input id={name} className="rounded-md border-[1.5px] border-[#dfdfdf] shadow-sm focus:border-primary focus-visible:ring-0" type="text" aria-invalid={errorMessage ? "true" : "false"} {...register(name, {
      valueAsNumber: inputProps.type === "number" || inputProps.inputMode === "numeric" || inputProps.inputMode === "decimal"
    })} {...inputProps}></input>
      <p className="ml-1 mt-2 text-sm font-medium capitalize">
        {errorMessage ? <span className="text-red-600" role="alert">
            {errorMessage}
          </span> : hint ? hint : null}
      </p>
    </div>;
}
export default Input;