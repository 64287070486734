"use client";

import React, { Fragment, useState } from "react";
import { DayPicker, DayPickerProps } from "react-day-picker";
import { sub } from "date-fns";
import "react-day-picker/dist/style.css";
import { useFloating, autoUpdate, offset, flip, shift, useClick, useDismiss, useRole, useInteractions, FloatingFocusManager } from "@floating-ui/react";
import Input, { InputProps } from "../InputField";
import { FieldValues } from "react-hook-form";
type DatePickerProps<T extends FieldValues> = DayPickerProps & {
  inputProps: InputProps<T>;
};
export default function DatePicker<T extends FieldValues>(props: DatePickerProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const {
    refs,
    floatingStyles,
    context
  } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const {
    getReferenceProps,
    getFloatingProps
  } = useInteractions([click, dismiss, role]);
  return <div ref={refs.setReference} {...getReferenceProps()} data-sentry-component="DatePicker" data-sentry-source-file="index.tsx">
      <Input {...props.inputProps} data-sentry-element="Input" data-sentry-source-file="index.tsx" />
      {isOpen && <FloatingFocusManager context={context} modal={false}>
          <div ref={refs.setFloating} {...getFloatingProps()} style={floatingStyles}>
            <DayPicker captionLayout="dropdown" fromYear={sub(new Date(), {
          years: 100
        }).getFullYear()} toYear={new Date().getFullYear()} {...props} className="bg-white" />
          </div>
        </FloatingFocusManager>}
    </div>;
}