import { useMutation } from "@tanstack/react-query";

import { validatePromoCode } from "../requests/cart";
import { useSetAtom } from "jotai";
import { promoCodeAtom } from "@/atoms/cart";
import { RESET } from "jotai/utils";

export const useValidatePromoCode = () => {
  const setPromoCode = useSetAtom(promoCodeAtom);
  const { data, isPending, error, mutate } = useMutation({
    mutationFn: (code: string) => {
      return validatePromoCode(code);
    },
    onSuccess: ({ data }) => {
      setPromoCode(data.code);
    },
    onError: () => {
      setPromoCode(RESET);
    },
  });
  return {
    validatedCode: data?.data,
    isLoading: isPending,
    error,
    validate: mutate,
  };
};
